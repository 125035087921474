import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { login } from "./authThunk";
import { roleType } from "../../utils/types/commonType";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

export interface InitialStateType {
  isAuth: boolean;
  token: string | null;
  expiration: string | null;
  refreshToken: string | null;
  role: roleType;
  userId: string | null;
  myStatus: boolean | null;
  name: string | null;
}

const initialState: InitialStateType = {
  isAuth: false,
  token: null,
  expiration: null,
  refreshToken: null,
  role: null,
  userId: null,
  myStatus: null,
  name: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    auth: (state, action: PayloadAction<{ token: string; expiration: string; refreshToken: string }>) => {
      const decode = jwtDecode<{
        UserId: string;
        Role: roleType;
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": string;
      }>(action.payload.token);
      localStorage.token = action.payload.token;
      localStorage.role = decode.Role;
      localStorage.expiration = action.payload.expiration;
      localStorage.refreshToken = action.payload.refreshToken;
      state.userId = decode.UserId;
      state.isAuth = true;
      state.role = decode.Role;
      state.token = action.payload.token;
      state.expiration = action.payload.expiration;
      state.refreshToken = action.payload.refreshToken;
      state.name = decode["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
    },

    logOut: (state) => {
      Cookies.remove("accessToken");
      state.isAuth = false;
      localStorage.token = "";
      localStorage.expiration = "";
      localStorage.role = "";
      localStorage.refreshToken = "";
      state.expiration = null;
      state.refreshToken = null;
      state.userId = null;
      state.role = null;
      state.token = null;
    },
    setStatus: (state, action: PayloadAction<boolean>) => {
      state.myStatus = action.payload;
    },
  },
});

export const { logOut, auth, setStatus } = authSlice.actions;

export default authSlice.reducer;
