import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { appelas } from "../../utils/types/storeType";

export type statusItem = {
  id: number;
  status: string;
};

export type typeItem = {
  id: number;
  type: string;
};

interface InitialStateType {
  statusList: Array<statusItem> | null;
  typesList: Array<typeItem> | null;
  listAppeals: Array<appelas> | undefined;
  currentAppeals: appelas | null;
}

const initialState: InitialStateType = {
  statusList: null,
  typesList: null,
  listAppeals: undefined,
  currentAppeals: null,
};

export const appealsReducer = createSlice({
  name: "appeals",
  initialState,
  reducers: {
    setStatusList: (state, action: PayloadAction<Array<statusItem>>) => {
      state.statusList = action.payload;
    },
    setTypesList: (state, action: PayloadAction<Array<typeItem>>) => {
      state.typesList = action.payload;
    },
    setAppealsList: (state, action: PayloadAction<Array<appelas>>) => {
      state.listAppeals = action.payload;
    },
    setCurrentAppeals: (state, action: PayloadAction<appelas | null>) => {
      state.currentAppeals = action.payload;
    },
  },
});

export const { setStatusList, setTypesList, setAppealsList, setCurrentAppeals } = appealsReducer.actions;

export default appealsReducer.reducer;
