import { useEffect, useState } from "react";
import { setTypeApp, useAppDispatch } from "./store";
import router from "./routes";
import { RouterProvider } from "react-router-dom";
import { typeAppDesktop, typeAppMobile } from "./utils/const";
import { init } from "./store/app/appThink";

function MainApp() {
  const dispatch = useAppDispatch();

  const [width, setWidth] = useState<number>(window.innerWidth);

  const routers = router;

  useEffect(() => {
    if (width < 769) {
      dispatch(setTypeApp(typeAppMobile));
    }
    dispatch(init());
  }, [dispatch]);

  window.onresize = () => {
    setWidth(window.innerWidth);
    if (width < 769) {
      dispatch(setTypeApp(typeAppMobile));
    } else {
      dispatch(setTypeApp(typeAppDesktop));
    }
  };

  return <RouterProvider router={routers} />;
}

export default MainApp;
