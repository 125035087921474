import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { openErrorNotification, openSuccessNotification } from "../../utils/message/message";
import { usersAPI } from "../../api";
import { Controller, useForm } from "react-hook-form";
import { requiredField } from "../../utils/validators/validators";

type locationType = {
  id: number;
  name: string;
};

type formType = {
  locationId: number;
};

export default function LocationSelect({
  currentLocation,
  userId,
}: {
  currentLocation: locationType | undefined;
  userId: string;
}) {
  const [location, setlocations] = useState<Array<locationType> | undefined>(undefined);

  let dataForSelect = location?.map((e: any) => ({ value: e.id, label: e.name }));
  const {
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<formType>();

  const changeLocation = async (id: number) => {
    try {
      await usersAPI.setUsersLocations({
        userId,
        locationId: id,
      });
      openSuccessNotification("Локация изменена");
      fetch();
    } catch (e) {
      openErrorNotification("Ошибка смены локация");
    }
  };

  const fetch = async () => {
    try {
      const response = await usersAPI.getUsersLocations();
      setlocations(response.data);
    } catch (e) {
      openErrorNotification("Ошибка получения списка локация");
    }
  };

  useEffect(() => {
    fetch();
    if (currentLocation) {
      reset({ locationId: currentLocation.id });
    }
    const subscription = watch((value, { name, type }) => {
      if (name === "locationId") {
        if (value.locationId) {
          changeLocation(value.locationId);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  return (
    <div className="operators__input-wrap">
      <label className="operators__label" style={{ display: "block" }} htmlFor="locationId">
        Локация
      </label>
      <Controller
        name="locationId"
        
        control={control}
        rules={requiredField}
        render={({ field }) => <Select {...field} style={{ width: 120 }} options={dataForSelect} className="operators__locations-select" />}
      />
      {errors?.locationId && (
        <div className="input__error">{errors.locationId?.message ? errors.locationId?.message : "Ошибка"}</div>
      )}
    </div>
  );
}
