import React from "react";
import { useAppSelector } from "../store/hooks";
import { Outlet } from "react-router-dom";
import Aside from "../components/Aside/Aside";


const MainLayout = () => {
 
  const asideIsOpen = useAppSelector((state) => state.app.isAsideActive);

  return (
    <>
      <main className={`main ${!asideIsOpen === true ? '_aside-close' : ''}`}>
        <Aside />
        <div className="main__right">
          <Outlet />
        </div>
      </main>
    </>
  );
};

export default MainLayout;
