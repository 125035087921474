import { PlusOutlined } from "@ant-design/icons";
import { Button, GetProp, Input, Modal, Upload, UploadFile, UploadProps } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store";
import { getAboutUser, updateUser } from "../store/users/usersThink";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { emailField, loginField } from "../utils/validators/validators";
import SwitchBanOperator from "../components/Operators/Switches/SwitchBanOperator";
import ChangePasswordOperator from "../components/Operators/modal/changePasswordOperator";
import LocationSelect from "../components/Operators/LocationSelect";
import TerminalsSelect from "../components/Operators/TerminalsSelect";

type Inputs = {
  userName: string;
  email: string;
  id: string;
};

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export default function OperatorPage() {
  const currentUser = useAppSelector((state) => state.users.aboutUser);
  const [changePasModalIsOpen, setChangePasModalIsOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const params = useParams();
  let id = params.id;

  const [fileList, setFileList] = useState<UploadFile[]>([
    {
      uid: "-1",
      name: "image.png",
      status: "done",
      url: "https://basik.ru/images/jason_lee_childs/085_jason_lee_childs.jpg",
    },
  ]);

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => setFileList(newFileList);

  const onSend = (file: any, fileList: any) => {
    console.log(file);
    console.log(fileList);

    return false;
  };

  const closeModal = () => {
    setChangePasModalIsOpen(false);
  };

  const uploadButton = (
    <button className="operators__avatar-new" style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined />
      <div style={{ marginLeft: "5px" }}>Загрузить новое</div>
    </button>
  );

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    dispatch(updateUser({ UserId: data.id, userName: data.userName, email: data.email }));
  };

  const fetch = () => {
    if (id) {
      dispatch(getAboutUser({ UserId: id }));
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getAboutUser({ UserId: id }));
    }
  }, []);

  useEffect(() => {
    reset({
      id: id,
      email: currentUser?.email,
      userName: currentUser?.userName,
    });
  }, [currentUser]);

  return (
    <div className="operators">
      <Button className="operators__back" onClick={() => navigate(-1)}>
        Назад
      </Button>
      <div className="operators__title title">Обзор оператора</div>
      <form className="operators__wrap" onSubmit={handleSubmit(onSubmit)}>
        <div className="operators__top">
          <div className="operators__avatar">
            <Upload
              beforeUpload={(file, fileList) => onSend(file, fileList)}
              listType="picture-card"
              fileList={fileList}
              onChange={handleChange}
              maxCount={1}
              onRemove={() => false}
              showUploadList={{
                showPreviewIcon: false,
              }}
            >
              {fileList.length >= 8 ? null : uploadButton}
            </Upload>
          </div>
          <div className="operators__main">
            <div className="operators__input-wrap">
              <div className="input">
                <label className="input__label" htmlFor="userName">
                  Имя
                </label>
                <Controller
                  name="userName"
                  control={control}
                  rules={loginField}
                  render={({ field }) => <Input placeholder="Имя пользователя" id="userName" {...field} />}
                />
                {errors?.userName && (
                  <div className="input__error">{errors.userName?.message ? errors.userName?.message : "Ошибка"}</div>
                )}
              </div>
            </div>
            <div className="operators__input-wrap">
              <div className="input">
                <label className="input__label" htmlFor="email">
                  Почта
                </label>
                <Controller
                  name="email"
                  control={control}
                  rules={emailField}
                  render={({ field }) => <Input placeholder="Почта" id="email" {...field} />}
                />
                {errors?.email && (
                  <div className="input__error">{errors.email?.message ? errors.email?.message : "Ошибка"}</div>
                )}
              </div>
            </div>
          </div>
          <div className="operators__btns">
            {currentUser?.role !== "Admin" && currentUser && (
              <SwitchBanOperator userId={currentUser?.id} ban={currentUser.isBlocked} fetch={fetch} />
            )}

            <Button
              className="operators__btn-pass-del"
              type="primary"
              size="large"
              htmlType="button"
              onClick={() => setChangePasModalIsOpen(true)}
            >
              Сбросить пароль
            </Button>
            <Button className="operators__btn-del" danger type="primary" size="large" htmlType="button">
              Удалить
            </Button>
          </div>
        </div>
        <div className="operators__center">
          {currentUser && <LocationSelect currentLocation={currentUser?.location} userId={currentUser?.id} />}
          {currentUser && <TerminalsSelect userId={currentUser.id} />}
        </div>
        <button className="operators__btn-save btn">Сохранить</button>
      </form>
      <Modal title="Изменение пароля" open={changePasModalIsOpen} onOk={closeModal} onCancel={closeModal} footer={null}>
        {currentUser && (
          <ChangePasswordOperator onClose={closeModal} name={currentUser?.userName} userId={currentUser.id} />
        )}
      </Modal>
    </div>
  );
}
