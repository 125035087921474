import { createBrowserRouter } from "react-router-dom";
import Error from "./components/Error/Error";
import {
  ADMIN_PATH,
  ERROR_PATH,
  MAIN_PATH,
  OPERATOR_PATH,
  REPORTS_PATH,
  SETTINGS_PATH,
  TERMINALS_PATH,
  VNC_PATH,
} from "./utils/const";
import MainLayout from "./layout/MainLayout";
import TerminalsPage from "./pages/TerminalsPage";
import Login from "./pages/Login";
import TerminalPage from "./pages/TerminalPage";
import VncSession from "./pages/VncSession";
import Administration from "./pages/Administration";
import OperatorPage from "./pages/OperatorPage";
import AppLayout from "./layout/AppLayout";
import ReportsPage from "./pages/ReportsPage";
import SettingsPage from "./pages/SettingsPage";

const router = createBrowserRouter([
  {
    path: MAIN_PATH,
    element: <Login />,
    errorElement: <Error />,
  },
  {
    path: MAIN_PATH,
    element: <AppLayout />,
    errorElement: <Error />,
    children: [
      {
        path: MAIN_PATH,
        element: <MainLayout />,
        errorElement: <Error />,

        children: [
          {
            path: TERMINALS_PATH,
            element: <TerminalsPage />,
            errorElement: <Error />,
          },
          {
            path: `${TERMINALS_PATH}/:id`,
            element: <TerminalPage />,
            errorElement: <Error />,
          },
          {
            path: ADMIN_PATH,
            element: <Administration />,
            errorElement: <Error />,
          },
          {
            path: `${OPERATOR_PATH}/:id`,
            element: <OperatorPage />,
            errorElement: <Error />,
          },
          {
            path: REPORTS_PATH,
            element: <ReportsPage />,
            errorElement: <Error />,
          },
          {
            path: SETTINGS_PATH,
            element: <SettingsPage />,
            errorElement: <Error />,
          },
        ],
      },
      {
        path: `${VNC_PATH}/:ip`,
        element: <VncSession />,
        errorElement: <Error />,
      },
    ],
  },

  {
    path: ERROR_PATH,
    element: <Error />,
  },
]);

export default router;
