import { ApiOutlined, PlusOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import settingsIcon from "../../../assets/images/settings.svg";
import PrinterIcon from "../../../assets/images/print.svg";
import ScannerIcon from "../../../assets/images/scan.svg";
import PrinterModal from "../modal/PrinterModal";
import ScannerModal from "../modal/ScannerModal";
import { useNavigate } from "react-router-dom";
import { VNC_PATH } from "../../../utils/const";
import { useAppDispatch } from "../../../store";
import TerminalAppeals from "../TerminalAppeals/TerminalAppeals";


export default function TerminalMain({ id, ip }: { id: string; ip: string }) {

  const [modalPrinter, setModalPrinter] = useState(false);
  const [modalScanner, setModalScanner] = useState(false);


  const navigate = useNavigate();
  const dispatch = useAppDispatch()

  const onVncSession = () => {
    return navigate(`${VNC_PATH}/${ip}`)
  };

  useEffect(() => {

  }, [])
  return (
    <>
      <div className="terminal__main-wrap">
        <div className="terminal__call-wrap terminal__main-item">
          <div className="terminal__call-monitor"></div>
          <Button className="terminal__call-btn" type="primary" icon={<VideoCameraOutlined />}>
            Начать сеанс связи
          </Button>
        </div>
        <div className="terminal__tip-wrap terminal__main-item">
          <div className="terminal__tip-monitor"></div>
          <Button className="terminal__tip-btn" type="primary" icon={<ApiOutlined />} onClick={onVncSession}>
            Подключиться к ТИП
          </Button>
        </div>
        
        <div className="terminal__devices-wrap terminal__main-item">
          <div className="terminal__device">
            <div className="terminal__device-type">Принтер</div>
            <div className="terminal__device-title-wrap">
              <div className="terminal__device-title">hp fssf</div>
              <div className="terminal__device-status _active"></div>
            </div>
            <div className="terminal__device-bottom">
              <button className="terminal__device-btn" onClick={() => setModalPrinter(true)}>
                <img className="terminal__device-btn-icon" src={PrinterIcon} alt="terminal__device-btn" />
              </button>
              <button className="terminal__device-btn">
                <img className="terminal__device-btn-icon" src={settingsIcon} alt="terminal__device-btn" />
              </button>
            </div>
          </div>
          <div className="terminal__device">
            <div className="terminal__device-type">Сканер</div>
            <div className="terminal__device-title-wrap">
              <div className="terminal__device-title">Canon</div>
              <div className="terminal__device-status _active"></div>
            </div>
            <div className="terminal__device-bottom">
              <button className="terminal__device-btn" onClick={() => setModalScanner(true)}>
                <img className="terminal__device-btn-icon" src={ScannerIcon} alt="terminal__device-btn" />
              </button>
              <button className="terminal__device-btn">
                <img className="terminal__device-btn-icon" src={settingsIcon} alt="terminal__device-btn" />
              </button>
            </div>
          </div>
          <div className="terminal__device">
            <div className="terminal__device-type">KKM</div>
            <div className="terminal__device-title-wrap">
              <div className="terminal__device-title">АТСП</div>
              <div className="terminal__device-status _active"></div>
            </div>
            <div className="terminal__device-bottom">
              <button className="terminal__device-btn">
                <img className="terminal__device-btn-icon" src={settingsIcon} alt="terminal__device-btn" />
              </button>
            </div>
          </div>
          <div className="terminal__device">
            <div className="terminal__device-type">Эквайринг</div>
            <div className="terminal__device-title-wrap">
              <div className="terminal__device-title">Ingenico</div>
              <div className="terminal__device-status"></div>
            </div>
            <div className="terminal__device-bottom">
              <button className="terminal__device-btn">
                <img className="terminal__device-btn-icon" src={settingsIcon} alt="terminal__device-btn" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={modalPrinter}
        title="Принтер"
        centered
        className="terminal__modal"
        onOk={() => setModalPrinter(false)}
        onCancel={() => setModalPrinter(false)}
        footer={null}
      >
        <PrinterModal name={"HP LaserJet 1050"} />
      </Modal>
      <Modal
        open={modalScanner}
        title="Сканер"
        centered
        className="terminal__modal"
        onOk={() => setModalScanner(false)}
        onCancel={() => setModalScanner(false)}
        footer={null}
      >
        <ScannerModal name={"Canon"} />
      </Modal>

    </>
  );
}
