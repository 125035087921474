import { InboxOutlined } from "@ant-design/icons";
import { Button, List, UploadProps } from "antd";
import Dragger from "antd/es/upload/Dragger";
import React from "react";

const props: UploadProps = {
  name: "file",
  multiple: true,
  action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      console.log("file uploaded successfully");
    } else if (status === "error") {
      console.log("file upload failed.");
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

export default function PrinterModal({ name }: { name: string }) {
  return (
    <div className="terminal__modal-inner">
      <div className="terminal__modal-name">{name}</div>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Выберите файл или перетащите сюда</p>
      </Dragger>
      <Button className={"terminal__modal-printer-btn"} type="primary">
        Отправить на печать
      </Button>
      <div className="terminal__modal-printer-stack">Очередь печати</div>
      <ul className="terminal__modal-printer-list">
        <li className="terminal__modal-printer-item">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit.
        </li>
        <li className="terminal__modal-printer-item">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit.
        </li>
        <li className="terminal__modal-printer-item">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit.
        </li>
      </ul>
    </div>
  );
}
