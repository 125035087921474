import React, { useEffect, useState } from "react";
import { openErrorNotification, openSuccessNotification } from "../../utils/message/message";
import { terminalAPI } from "../../api";
import { Select } from "antd";

type terminalType = {
  user: string;
  id: string;
  name: string;
  currentIP: string;
  astipStatus: string;
};

export default function TerminalsSelect({ userId }: { userId: string }) {
  const [listTerminals, setListTerminals] = useState<null | Array<{ value: string; label: string }>>(null);

  const [userTerminals, setUserTerminals] = useState<null | Array<{ value: string; label: string }>>(null);

  const defaultValue = userTerminals?.map((e)=>e.value)

  let dataForSelect = (array: Array<terminalType>) => {
    return array?.map((e: any) => ({ value: e.id, label: `${e.name}, ${e.currentIP}` }));
  };

  const handleChange = async (value: string[]) => {
    try {
      await terminalAPI.setUserTerminals({userId, terminalsId: value});
      openSuccessNotification("Терминалы пользователя изменены");
      fetch();
    } catch (e) {
      openErrorNotification("Ошибка изменения терминалов пользователя");
    }
    console.log(`selected ${value}`);
    //fetch()
  };

  const fetch = async () => {
    try {
      const userTerminals = await terminalAPI.getList({ UserId: userId });
      const allTerminals = await terminalAPI.getList({});
      setListTerminals(dataForSelect(allTerminals.data));
      setUserTerminals(dataForSelect(userTerminals.data));
    } catch (e: any) {
      openErrorNotification("Ошибка загрузки терминалов");
    }
  };

  useEffect(() => {
    fetch()
  }, []);
  return (
    <div className="operators__input-wrap">
      <label className="operators__label" style={{ display: "block" }} htmlFor="terminals">
        Привязанные терминалы
      </label>
      {listTerminals && userTerminals && (
        <Select
          mode="multiple"
          defaultValue={defaultValue}
          style={{ width: "100%" }}
          placeholder="Задайте терминалы пользователю"
          onChange={handleChange}
          options={listTerminals}
        />
      )}
    </div>
  );
}
