import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { openErrorNotification, openSuccessNotification } from "../../../../utils/message/message";
import { requiredField } from "../../../../utils/validators/validators";
import { Button, Select } from "antd";
import { terminalAPI } from "../../../../api";
import { currentTerminalType } from "../TerminalList";
import { useEffect } from "react";
import { getStatusList } from "../../../../store/terminals/terminalsThink";

type addNewStatus = {
  status: number;
};

const AddNewStatus = ({
  onClose,
  fetch,
  currentTerminal,
}: {
  onClose: () => void;
  fetch: () => void;
  currentTerminal: currentTerminalType | null;
}) => {
  const statusList = useAppSelector((state) => state.terminals.listStatus);
  const dispatch = useAppDispatch();

  const translateStatusToRus = (status: string) => {
    let trStatus = "";
    switch (status) {
      case "None": {
        trStatus = "Отсутствует";
        break;
      }
      case "Disabled": {
        trStatus = "Отключен";
        break;
      }
      case "Offline": {
        trStatus = "Не в сети";
        break;
      }
      case "Online": {
        trStatus = "В сети";
        break;
      }
      default: {
        trStatus = "Не определен";
      }
    }
    return trStatus;
  };
  const transformStatusToId = (status: string) => {
    let trStatus: number;
    switch (status) {
      case "Disabled": {
        trStatus = 3;
        break;
      }
      case "Offline": {
        trStatus = 2;
        break;
      }
      case "Online": {
        trStatus = 1;
        break;
      }
      default: {
        trStatus = 4;
      }
    }
    return trStatus;
  };

  let dataForSelect = statusList?.map((e: any) => ({ value: e.id, label: translateStatusToRus(e.status) }));

  if (dataForSelect) {
    dataForSelect.push({ value: 4, label: "Не определен" });
  }

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<addNewStatus>({
    defaultValues: { status: currentTerminal ? transformStatusToId(currentTerminal?.status) : 4 },
  });

  const onSubmit: SubmitHandler<addNewStatus> = async (data) => {
    try {
      if (currentTerminal) {
        await terminalAPI.addNewStatus({ id: currentTerminal?.id, status: data.status });
      }
      await fetch();
      onClose();
      openSuccessNotification("Статус изменен");
    } catch (e: any) {
      openErrorNotification("Ошибка изменения статуса");
    }
  };

  useEffect(() => {
    dispatch(getStatusList());
    return () => {
      reset();
    };
  }, []);
  
  useEffect(() => {
    reset({ status: currentTerminal ? transformStatusToId(currentTerminal?.status) : 4 });
  }, [currentTerminal]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="inputs-wrap">
        <div className="inputs-wrap__row">
          <div className="input">
            <label className="input__label" htmlFor="status">
              Статус
            </label>
            <Controller
              name="status"
              control={control}
              rules={requiredField}
              render={({ field }) => <Select {...field} style={{ width: 120 }} options={dataForSelect} />}
            />
            {errors?.status && (
              <div className="input__error">{errors.status?.message ? errors.status?.message : "Ошибка"}</div>
            )}
          </div>
        </div>
      </div>
      <div className="terminals__form-add-bottom">
        <Button htmlType="button" onClick={onClose}>
          Отмена
        </Button>
        <Button htmlType="submit" type="primary">
          Добавить
        </Button>
      </div>
    </form>
  );
};

export default AddNewStatus;
