import { Navigate, Outlet } from "react-router-dom";
import Loading from "../components/Loading/Loading";
import { useAppSelector } from "../store";
import { MAIN_PATH } from "../utils/const";

const AppLayout = () => {
  const loading = useAppSelector((state) => state.app.loading);
  const isInit = useAppSelector((state) => state.app.init);
  const isAuth = useAppSelector((state) => state.auth.isAuth)

  if (!isInit) {
    return <Loading />;
  }

  if (!isAuth) {
    return <Navigate to={MAIN_PATH} />
  }

  return (
    <>
      {loading ? <Loading /> : ""}
      <Outlet />
    </>
  )
}

export default AppLayout;