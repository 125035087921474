import React, { useEffect, useState } from "react";
import { setStatus, useAppDispatch, useAppSelector } from "../../store";
import { getUser } from "../../store/users/usersThink";
import { Switch } from "antd";
import { usersAPI } from "../../api";
import { openErrorNotification } from "../../utils/message/message";

export default function SwitchStatus({ userId }: { userId: string }) {
  const isActive = useAppSelector((state) => state.users.currentUser?.isActive);
  const dispatch = useAppDispatch();
  const [switchIsActive, setSwitch] = useState(isActive);

  const onChange = async (checked: boolean) => {
    try {
      setSwitch(checked)
      dispatch(setStatus(checked));
      const response = await usersAPI.switchStatus();
    } catch (e: any) {
      if (e.response.data) {
        openErrorNotification("Ошибка получения информации о пользователе");
      }
    }
    dispatch(getUser({ UserId: userId }));
  };

  useEffect(() => {
    dispatch(getUser({ UserId: userId }));
  }, []);

  useEffect(()=>{
    setSwitch(isActive)
  }, [isActive])

  return (
    <div className="aside__top-status">
      <span className="aside__top-status-text">Статус</span>
      <Switch checked={switchIsActive} onChange={onChange} />
    </div>
  );
}
