import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type abonent = {
  id: string
  personalAcc: string
  email: string
  phoneNumber: string
}


interface InitialStateType {
  abonentsList: Array<abonent> | null;
}

const initialState: InitialStateType = {
  abonentsList: null
};

export const abonentsReducer = createSlice({
  name: "abonents",
  initialState,
  reducers: {
    setAbonentsList: (state, action: PayloadAction<Array<abonent>>) => {
      state.abonentsList = action.payload;
    },

  },
});

export const { setAbonentsList, } = abonentsReducer.actions;

export default abonentsReducer.reducer;
