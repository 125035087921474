import { terminalAPI } from "../../api";
import { openErrorNotification, openSuccessNotification } from "../../utils/message/message";
import { loadingStatus } from "../app/appSlice";
import { setList, setListStatus, setTerminal, setTerminalConfig } from "./terminalsSlice";

export const getListTerminals =
  ({ UserId }: { UserId?: string }) =>
  async (dispatch: any) => {
    dispatch(loadingStatus(true));

    try {
      const response = await terminalAPI.getList({
        ...(UserId && { UserId }),
      });
      dispatch(setList(response.data));
    } catch (e: any) {
      if (e.response.data) {
        openErrorNotification("Ошибка получения списка терминалов");
      }
    }
    dispatch(loadingStatus(false));
  };

export const getTerminal = (TerminalId: string) => async (dispatch: any) => {
  dispatch(loadingStatus(true));

  try {
    const response = await terminalAPI.getTerminalInfo({ TerminalId });
    dispatch(setTerminal(response.data));
  } catch (e: any) {
    if (e.response.data) {
      openErrorNotification("Ошибка получения информации о терминале");
    }
  }
  dispatch(loadingStatus(false));
};

export const getStatusList = () => async (dispatch: any) => {
  dispatch(loadingStatus(true));

  try {
    const response = await terminalAPI.getStatusList();
    dispatch(setListStatus(response.data));
  } catch (e: any) {
    if (e.response.data) {
      openErrorNotification(`Ошибка получения списка статусов. ${e.response.data.message}`);
    }
  }
  dispatch(loadingStatus(false));
};

export const getTerminalConfig = (TerminalId: string) => async (dispatch: any) => {
  dispatch(loadingStatus(true));

  try {
    const response = await terminalAPI.getTerminalConfig({ TerminalId });
    dispatch(setTerminalConfig(response.data));
  } catch (e: any) {
    if (e.response.data) {
      openErrorNotification("Ошибка получения конфига терминале");
    }
  }
  dispatch(loadingStatus(false));
};

export const getUserTerminals = () => async (dispatch: any) => {
  dispatch(loadingStatus(true));

  try {
    const response = await terminalAPI.getUserTerminals();
    dispatch(setList(response.data));
  } catch (e: any) {
    if (e.response.data) {
      openErrorNotification("Ошибка получения информации о терминале");
    }
  }
  dispatch(loadingStatus(false));
};

export const editTerminal =
  ({ id, name, currentIP, astipConfigId }: { id: string; name: string; currentIP: string; astipConfigId?: string }) =>
  async (dispatch: any) => {
    dispatch(loadingStatus(true));

    try {
      await terminalAPI.editTerminal({
        id,
        name,
        currentIP,
        astipConfigId,
      });
      dispatch(getTerminal(id));
      dispatch(getTerminalConfig(id));
      openSuccessNotification("Основаная информация о терминале изменена");
    } catch (e: any) {
      if (e.response.data) {
        openErrorNotification("Ошибка получения информации о терминале");
      }
    }
    dispatch(loadingStatus(false));
  };
