import React, { useState } from "react";
import { terminalItem } from "../../../store/terminals/terminalsSlice";
import { Link } from "react-router-dom";
import { TERMINALS_PATH } from "../../../utils/const";
import { MenuUnfoldOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Popconfirm } from "antd";
import { loadingStatus, useAppDispatch } from "../../../store";
import AddNewStatus from "./modal/addNewStatus";
import { terminalAPI } from "../../../api";
import { openErrorNotification, openSuccessNotification } from "../../../utils/message/message";

export type currentTerminalType = {
  id: string;
  status: string;
};

export default function TerminalList({
  listTerminals,
  fetch,
}: {
  listTerminals: Array<terminalItem> | null;
  fetch: () => void;
}) {
  const dispatch = useAppDispatch();
  const [addNewStatusIsOpen, setAddNewStatusIsOpen] = useState(false);
  const [currentTerminal, setCurrentTerminal] = useState<null | currentTerminalType>(null);

  const onDeleteTerminal = async (id: string) => {
    dispatch(loadingStatus(true));

    try {
      await terminalAPI.deleteTerminal({ Id: id });
      openSuccessNotification("Успешное удаление терминала");
    } catch (e: any) {
      if (e.response.data) {
        openErrorNotification(`Ошибка удаления терминала. ${e.response.data.message}`);
      }
    }
    fetch();
    dispatch(loadingStatus(false));
  };

  const closeModal = () => {
    setAddNewStatusIsOpen(false);
  };

  const onSetStatus = (id: string, status: string) => {
    setCurrentTerminal({ id, status });
    setAddNewStatusIsOpen(true);
  };

  return (
    <>
      {listTerminals && listTerminals?.length > 0 ? (
        <ul className="terminals__list">
          {listTerminals.map((item) => (
            <li className="terminals__item" key={item.id}>
              <div className="terminals__name">{item.name}</div>
              <div className="terminals__info">
                <div className="terminals__ip">{item.currentIP}</div>
                <div className={`terminals__status ${item.astipStatus === "Online" && "_active"}`}></div>
              </div>
              <Link to={`${TERMINALS_PATH}/${item.id}`} className="terminals__link btn">
                <MenuUnfoldOutlined style={{ fontSize: "35px" }} />
              </Link>
              <div className="terminals__btns-wrap">
                <Button className="terminals__btn-sm" onClick={() => onSetStatus(item.id, item.astipStatus)}>
                  Установить статус
                </Button>
                <Popconfirm
                  title="Удалить терминал?"
                  okText="Да"
                  onConfirm={() => onDeleteTerminal(item.id)}
                  cancelText="Нет"
                  okButtonProps={{ danger: true }}
                  icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                >
                  <Button className="terminals__btn-sm" danger>
                    Удалить
                  </Button>
                </Popconfirm>

                <Button className="terminals__btn-sm">Кнопка 3</Button>
                <Button className="terminals__btn-sm">Кнопка 4</Button>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div>Нет ни одного терминала</div>
      )}
      <Modal title="Установка статуса" open={addNewStatusIsOpen} onOk={closeModal} onCancel={closeModal} footer={null}>
        <AddNewStatus currentTerminal={currentTerminal} fetch={fetch} onClose={closeModal} />
      </Modal>
    </>
  );
}
