import { InboxOutlined } from "@ant-design/icons";
import { Button, List, UploadProps } from "antd";
import Dragger from "antd/es/upload/Dragger";
import React from "react";

export default function ScannerModal({ name }: { name: string }) {
  return (
    <div className="terminal__modal-inner">
      <div className="terminal__modal-name">{name}</div>

      <Button className={"terminal__modal-scanner-btn"} size="large" type="primary">
        Сканировать
      </Button>

      <Button className={"terminal__modal-scanner-btn"} size="large">
        Сохранить
      </Button>
    </div>
  );
}
