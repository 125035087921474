import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { openErrorNotification, openSuccessNotification } from "../../../../utils/message/message";
import { Button, Input } from "antd";
import { ipField, requiredField } from "../../../../utils/validators/validators";
import { terminalAPI } from "../../../../api";

type addNewTerminal = {
  name: string;
  currentIP: string;
};

const AddNewTerminal = ({ onClose, fetch }: { onClose: () => void; fetch: () => void }) => {
 
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<addNewTerminal>();

  const onSubmit: SubmitHandler<addNewTerminal> = async (data) => {
    try {
      await terminalAPI.addNewterminal({ name: data.name, currentIP: data.currentIP });
      reset();
      await fetch();
      onClose();
      openSuccessNotification("Успешное добавление терминала");
    } catch (e: any) {
      openErrorNotification("Ошибка при добавление терминала");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="inputs-wrap">
        <div className="inputs-wrap__row">
          <div className="input">
            <label className="input__label" htmlFor="name">
              Имя
            </label>
            <Controller
              name="name"
              control={control}
              rules={requiredField}
              render={({ field }) => <Input placeholder="Имя" id="name" {...field} />}
            />
            {errors?.name && (
              <div className="input__error">{errors.name?.message ? errors.name?.message : "Ошибка"}</div>
            )}
          </div>
        </div>
        <div className="inputs-wrap__row">
          <div className="input">
            <label className="input__label" htmlFor="currentIP">
              ip-адрес
            </label>
            <Controller
              name="currentIP"
              control={control}
              rules={ipField}
              render={({ field }) => <Input placeholder="ip" id="currentIP" {...field} />}
            />
            {errors?.currentIP && (
              <div className="input__error">{errors.currentIP?.message ? errors.currentIP?.message : "Ошибка"}</div>
            )}
          </div>
        </div>
      </div>
      <div className="terminals__form-add-bottom">
        <Button htmlType="button" onClick={onClose}>
          Отмена
        </Button>
        <Button htmlType="submit" type="primary">
          Добавить
        </Button>
      </div>
    </form>
  );
};

export default AddNewTerminal;
