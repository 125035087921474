import React, { useEffect, useState } from "react";
import { openErrorNotification, openSuccessNotification } from "../../../utils/message/message";
import { Switch } from "antd";
import { usersAPI } from "../../../api";
import { roleType } from "../../../utils/types/commonType";

export default function SwitchBanOperator({ userId, ban, fetch }: { userId: string; ban: boolean; fetch: () => void}) {

  const [switchIsActive, setSwitch] = useState(ban);

  const onChange = async (checked: boolean) => {
    try {
      setSwitch(checked);
      if (checked) {
        await usersAPI.banUser({ UserId: userId });
        openSuccessNotification('Пользователь заблокирован')
      } else {
        await usersAPI.unBanUser({ UserId: userId });
        openSuccessNotification('Пользователь раззаблокирован')
      }
      
      fetch();
    } catch (e: any) {
      if (e.response.data) {
        openErrorNotification("Ошибка установки статуса");
      }
    }
  };

  useEffect(() => {
    setSwitch(ban);
  }, [ban]);

  return (
    <div className="operators__ban-wrap">
      <span className="operators__ban-text">Блокировка</span>
      <Switch checked={switchIsActive} onChange={onChange} />
    </div>
  );
}
