import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { roleType, userType } from "../../utils/types/commonType";

export type terminalItem = {
  id: string;
  name: string;
  currentIP: string;
  astipStatus: string;
  user: userType | null;
};

export type terminalConfig = {
  terminalId: string;
  configId: string;
  config: Array<any>;
};

interface InitialStateType {
  terminalsList: Array<terminalItem> | null;
  currentTerminal: terminalItem | null;
  listStatus: null | Array<{ id: number; status: string }>;
  currentTerminalConfig: terminalConfig | null;
}

const initialState: InitialStateType = {
  terminalsList: null,
  currentTerminal: null,
  listStatus: null,
  currentTerminalConfig: null,
};

export const terminalsReducer = createSlice({
  name: "terminals",
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<Array<terminalItem>>) => {
      state.terminalsList = action.payload;
    },
    setTerminal: (state, action: PayloadAction<terminalItem | null>) => {
      state.currentTerminal = action.payload;
    },
    setListStatus: (state, action: PayloadAction<Array<{ id: number; status: string }>>) => {
      state.listStatus = action.payload;
    },
    setTerminalConfig: (state, action: PayloadAction<terminalConfig | null>) => {
      state.currentTerminalConfig = action.payload;
    },
  },
});

export const { setList, setTerminal, setListStatus, setTerminalConfig } = terminalsReducer.actions;

export default terminalsReducer.reducer;
