import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { getListTerminals, getUserTerminals } from "../store/terminals/terminalsThink";
import TerminalList from "../components/Terminal/TerminalList/TerminalList";
import { Button, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import AddNewTerminal from "../components/Terminal/TerminalList/modal/addNewTerminal";

export default function TerminalsPage() {
  const listTerminals = useAppSelector((state) => state.terminals.terminalsList);
  const role = useAppSelector((state) => state.auth.role);
  const userId = useAppSelector((state) => state.auth.userId);
  const dispatch = useAppDispatch();

  const [addNewTerminalIsOpen, setAddNewTerminalIsOpen] = useState(false);

  const closeModal = () => {
    setAddNewTerminalIsOpen(false);
  };

  const fetch = async () => {
    if (role === "Admin") {
      dispatch(getListTerminals({}));
    } else {
      dispatch(getUserTerminals());
    }
  };

  useEffect(() => {
    if (role === "Admin") {
      dispatch(getListTerminals({}));
    } else {
      dispatch(getUserTerminals());
    }
  }, []);

  return (
    <div className="terminals">
      <div className="terminals__title title">Терминалы</div>

      {role === "Admin" && (
        <Button className="terminals__add" icon={<PlusOutlined />} onClick={() => setAddNewTerminalIsOpen(true)}>
          Добавить терминал
        </Button>
      )}

      <TerminalList listTerminals={listTerminals} fetch={fetch} />

      <Modal
        title="Добавление нового терминала"
        open={addNewTerminalIsOpen}
        onOk={closeModal}
        onCancel={closeModal}
        footer={null}
      >
        <AddNewTerminal fetch={fetch} onClose={closeModal} />
      </Modal>
    </div>
  );
}
