import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { Button, Input } from 'antd'
import React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Link, Navigate } from 'react-router-dom'
import { loginField, passField } from '../utils/validators/validators'
import { useAppDispatch, useAppSelector } from '../store'
import { login } from '../store/auth/authThunk'
import { TERMINALS_PATH } from '../utils/const'

type Inputs = {
  login: string
  password: string
}

export default function Login() {

  const isAuth = useAppSelector((state) => state.auth.isAuth)
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>()
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    dispatch(login({ email: data.login, password: data.password }))
  }

  if (isAuth) {
    return <Navigate to={TERMINALS_PATH} />
  }

  return (
    <div className='login'>
      <form className="login__inner" onSubmit={handleSubmit(onSubmit)}>
        <div className="login__title">
          Авторизация
        </div>
        <div className="login__inner-input">
          <div className="input">
            <label className="input__label" htmlFor="name">
              Имя пользователя
            </label>
            <Controller
              name="login"
              control={control}
              rules={loginField}
              render={({ field }) => (
                <Input placeholder='Имя' size="large" id='name' {...field} />
              )}
            />
            {
              errors?.login && <div className='input__error'>
                {errors.login?.message ? errors.login?.message : 'Ошибка'}
              </div>
            }

          </div>
          <div className="input">
            <label className="input__label" htmlFor="pass">
              Пароль
            </label>
            <Controller
              name="password"
              control={control}
              rules={passField}
              render={({ field }) => (
                <Input.Password {...field} placeholder='Ваш пароль' size="large" id='pass' iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
              )}
            />
            {
              errors?.password && <div className='input__error'>
                {errors.password?.message ? errors.password?.message : 'Ошибка'}
              </div>
            }

          </div>
        </div>
        <Button className='login__btn' type='primary' size='large' htmlType='submit'>
          Войти
        </Button>
        <div className="login__bottom">
          <Link to={''} className='login__link'>
            Забыли пароль?</Link>
        </div>
      </form>
    </div>
  )
}
