import { createAsyncThunk } from "@reduxjs/toolkit";

import { loadingStatus, setInit } from "./appSlice";
import { auth } from "../auth/authSlice";
import Cookies from "js-cookie";
import { authAPI } from "../../api";
import { openErrorNotification } from "../../utils/message/message";

export const init = () => async (dispatch: any) => {
  dispatch(loadingStatus(true));
  const currentToken = localStorage.getItem("token");
  if (currentToken) {
    const currentRefreshToken = localStorage.getItem("refreshToken");
    const currentExpiration = localStorage.getItem("expiration");
    Cookies.set("accessToken", currentToken);
    if (currentToken && currentToken.length > 2 && currentRefreshToken && currentExpiration) {
      try {
        const response = await authAPI.refreshToken({ accessToken: currentToken, refreshToken: currentRefreshToken });
        Cookies.set("accessToken", response.data.token);
        dispatch(
          auth({
            token: response.data.token,
            refreshToken: response.data.refreshToken,
            expiration: response.data.expiration,
          })
        );
      } catch (e) {
        openErrorNotification("Ошибка авторизации");
      }
    }
  }
  dispatch(setInit(true));
  dispatch(loadingStatus(false));
};
