import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./app/appSlice";
import authReducer from "./auth/authSlice";
import terminalsReducer from "./terminals/terminalsSlice";
import usersReducer from "./users/usersSlice";
import appealsReducer from "./appeals/appealsSlice";
import abonentsReducer from "./abonents/abonentsSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    terminals: terminalsReducer,
    users: usersReducer,
    appeals: appealsReducer,
    abonents: abonentsReducer
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),

  devTools: process.env.NODE_ENV !== "production",
});

//@ts-ignore
window.store = store;
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
