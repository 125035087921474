import { terminalAPI, usersAPI } from "../../api";
import { openErrorNotification, openSuccessNotification } from "../../utils/message/message";
import { userType } from "../../utils/types/commonType";
import { getUsersType } from "../../utils/types/storeType";
import { loadingStatus } from "../app/appSlice";
import { setStatus } from "../auth/authSlice";
import { setAboutUser, setCurrentUser, setUsersList } from "./usersSlice";

export const getUsers =
  ({ UserName, Email, IsActive, Role, IsBlocked }: getUsersType) =>
  async (dispatch: any) => {
    dispatch(loadingStatus(true));

    try {
      const response = await usersAPI.getUsers({
        ...(UserName && { UserName }),
        ...(Email && { Email }),
        ...(IsActive && { IsActive }),
        ...(Role && { Role }),
        ...(IsBlocked && { IsBlocked }),
      });

      const standartArray = response.data.map((item: userType) => ({ ...item, key: item.id }));

      await Promise.all(
        standartArray.map(async (item: any) => {
          const terminals = await terminalAPI.getList({ UserId: item.id });
          return {
            ...item,
            terminals: terminals.data,
          };
        })
      ).then((values) => {
        dispatch(setUsersList(values));
      });
    } catch (e: any) {
      if (e.response.data) {
        openErrorNotification("Ошибка получения списка пользователей");
      }
    }
    dispatch(loadingStatus(false));
  };

export const getUser =
  ({ UserId }: { UserId: string }) =>
  async (dispatch: any) => {
    dispatch(loadingStatus(true));

    try {
      const response = await usersAPI.getUser({ UserId });

      dispatch(setCurrentUser(response.data));
    } catch (e: any) {
      if (e.response.data) {
        openErrorNotification("Ошибка получения информации о пользователе");
      }
    }
    dispatch(loadingStatus(false));
  };

export const getAboutUser =
  ({ UserId }: { UserId: string }) =>
  async (dispatch: any) => {
    dispatch(loadingStatus(true));

    try {
      const response = await usersAPI.getUser({ UserId });

      dispatch(setAboutUser(response.data));
    } catch (e: any) {
      if (e.response.data) {
        openErrorNotification("Ошибка получения информации о пользователе");
      }
    }
    dispatch(loadingStatus(false));
  };

export const updateUser =
  ({ UserId, userName, email }: { UserId: string; userName: string; email: string }) =>
  async (dispatch: any) => {
    dispatch(loadingStatus(true));

    try {
      await usersAPI.updateUser({ id: UserId, userName, email });
      const response = await usersAPI.getUser({ UserId });

      openSuccessNotification("Данные о пользователе обновлены");
      dispatch(setAboutUser(response.data));
    } catch (e: any) {
      if (e.response.data) {
        openErrorNotification("Ошибка получения информации о пользователе");
      }
    }
    dispatch(loadingStatus(false));
  };
