import React, { useEffect } from "react";
import { terminalItem, terminalConfig } from "../../../store/terminals/terminalsSlice";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch } from "../../../store";
import { Button, Input } from "antd";
import { ipField, requiredField } from "../../../utils/validators/validators";
import { editTerminal } from "../../../store/terminals/terminalsThink";

type Inputs = {
  id: string;
  name: string;
  currentIP: string;
};

export default function TerminalSettingsMain({
  terminalInfo,
  terminalConfig,
}: {
  terminalInfo: terminalItem;
  terminalConfig: terminalConfig;
}) {
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    console.log(data);
    dispatch(editTerminal({ id: data.id, name: data.name, currentIP: data.currentIP }));
  };
  useEffect(() => {
    reset({ id: terminalInfo.id, currentIP: terminalInfo.currentIP, name: terminalInfo.name });
  }, [terminalInfo]);

  return (
    <form className="terminalSettings__main" onSubmit={handleSubmit(onSubmit)}>
      <div className="terminalSettings__title _h4">Основная информация</div>
      <div className="input-inner">
        <div className="input-inner__row">
          <div className="input">
            <label className="input__label" htmlFor="name">
              Название
            </label>
            <Controller
              name="name"
              control={control}
              rules={requiredField}
              render={({ field }) => <Input placeholder="Имя" id="name" {...field} />}
            />
            {errors?.name && (
              <div className="input__error">{errors.name?.message ? errors.name?.message : "Ошибка"}</div>
            )}
          </div>
          <div className="input">
            <label className="input__label" htmlFor="currentIP">
              Адрес
            </label>
            <Controller
              name="currentIP"
              control={control}
              rules={ipField}
              render={({ field }) => <Input placeholder="0.0.0.0" id="currentIP" {...field} />}
            />
            {errors?.currentIP && (
              <div className="input__error">{errors.currentIP?.message ? errors.currentIP?.message : "Ошибка"}</div>
            )}
          </div>
        </div>
      </div>
      <Button className="terminalSettings__main-btn" type="primary" htmlType="submit">
        Сохранить
      </Button>
    </form>
  );
}
