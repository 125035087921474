import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { userType, userTypeExpansion } from "../../utils/types/commonType";

interface InitialStateType {
  usersList: Array<userTypeExpansion> | undefined;
  currentUser: userTypeExpansion | null;
  aboutUser: userTypeExpansion | null;
}

const initialState: InitialStateType = {
  usersList: undefined,
  currentUser: null,
  aboutUser: null
};

export const usersReducer = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsersList: (state, action: PayloadAction<Array<userTypeExpansion>>) => {
      state.usersList = action.payload;
    },
    setCurrentUser: (state, action: PayloadAction<userTypeExpansion>) => {
      state.currentUser = action.payload;
    },
    setAboutUser: (state, action: PayloadAction<userTypeExpansion>) => {
      state.aboutUser = action.payload;
    },
  },
});

export const { setUsersList, setCurrentUser,setAboutUser } = usersReducer.actions;

export default usersReducer.reducer;
