import {
  ClusterOutlined,
  ControlOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LogoutOutlined,
  SettingOutlined,
  SnippetsOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { ADMIN_PATH, REPORTS_PATH, SETTINGS_PATH, TERMINALS_PATH } from "../../utils/const";
import { logOut, setAsideActive, useAppDispatch, useAppSelector } from "../../store";
import SwitchStatus from "../SwitchStatus/SwitchStatus";

const Aside = () => {
  const asideIsOpen = useAppSelector((state) => state.app.isAsideActive);
  const role = useAppSelector((state) => state.auth.role);
  const name = useAppSelector((state) => state.auth.name);
  const userId = useAppSelector((state) => state.auth.userId);
  const dispatch = useAppDispatch();

  const toggleAsideOpen = () => {
    dispatch(setAsideActive(!asideIsOpen));
  };

  const onLogOut = () => {
    dispatch(logOut());
  };

  return (
    <aside className={`aside ${asideIsOpen === false ? "_close" : ""}`}>
      <button className="aside__toggle" type="button" onClick={toggleAsideOpen}>
        {asideIsOpen ? <DoubleLeftOutlined /> : <DoubleRightOutlined />}
      </button>
      <div className="aside__top-wrap">
        <div className="aside__top ">
          <div className="aside__top-name">
            <Link className="aside__img-wrap" to={TERMINALS_PATH}>
              <UserOutlined style={{ fontSize: "60px" }} />
            </Link>
            {asideIsOpen && (
              <div className="aside__top-text">
                <div className="aside__text-name">{name ? name : 'Ошибка'}</div>
                <div className="aside__bold-text">{role === "Admin" ? "Администратор" : "Оператор"}</div>
              </div>
            )}
          </div>
          {role !== "Admin" && userId && <SwitchStatus userId={userId}/>}
        </div>

        <ul className="aside__list">
          <li className="aside__item">
            <NavLink to={TERMINALS_PATH} className={"aside__link"}>
              <ClusterOutlined style={{ fontSize: "30px" }} className="aside__link-icon" />
              {asideIsOpen && "Терминалы"}
            </NavLink>
          </li>
          <li className="aside__item">
            <NavLink to={REPORTS_PATH} className={"aside__link"}>
              <SnippetsOutlined style={{ fontSize: "30px" }} className="aside__link-icon" />
              {asideIsOpen && "Отчеты"}
            </NavLink>
          </li>
          <li className="aside__item">
            <NavLink to={SETTINGS_PATH} className={"aside__link"}>
              <SettingOutlined style={{ fontSize: "30px" }} className="aside__link-icon" />
              {asideIsOpen && "Настройки"}
            </NavLink>
          </li>
          {role === "Admin" && (
            <li className="aside__item">
              <NavLink to={ADMIN_PATH} className={"aside__link"}>
                <ControlOutlined style={{ fontSize: "30px" }} className="aside__link-icon" />
                {asideIsOpen && "Панель администратора"}
              </NavLink>
            </li>
          )}
        </ul>
      </div>
      <div className="aside__bottom">
        <button className="aside__exit btn" onClick={onLogOut}>
          <LogoutOutlined style={{ fontSize: "30px" }} className="aside__link-icon" />
          {asideIsOpen && "Выйти"}
        </button>
      </div>
    </aside>
  );
};

export default Aside;
