import { abonentAPI } from "../../api";
import { openErrorNotification } from "../../utils/message/message";
import { getAbonents } from "../../utils/types/commonType";

import { loadingStatus } from "../app/appSlice";
import { setAbonentsList } from "./abonentsSlice";

export const getAbonenetsList =
  ({ Filter }: { Filter?: string }) =>
  async (dispatch: any) => {
    dispatch(loadingStatus(true));

    try {
      const response = await abonentAPI.getAbonent({
        ...(Filter && { Filter }),
      });

      dispatch(setAbonentsList(response.data));
    } catch (e: any) {
      if (e.response.data) {
        openErrorNotification("Ошибка получения списка абонентов");
      }
    }
    dispatch(loadingStatus(false));
  };
