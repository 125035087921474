import { Button, Segmented, Table, TableColumnsType } from "antd";
import Input, { SearchProps } from "antd/es/input";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { OPERATOR_PATH } from "../utils/const";
import Operators from "../components/Operators/Operators";



export default function Administration() {

  const [value, setValue] = useState<string | number>("Операторы");

  return (
    <div className="administration">
      <div className="administration__title title">Панель администратора</div>
      <div className="administration__wrap">
        <div className="administration__top">
          <Segmented options={["Операторы", "Справочники"]} value={value} onChange={setValue} />
        </div>
        {value === "Операторы" && (
          <Operators/>
        )}
      </div>
    </div>
  );
}
