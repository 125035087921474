import { Button, Select, TableProps } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { addAppeals } from "../../../utils/types/commonType";
import { editAppeal, getAppeal } from "../../../store/appeals/appealsThink";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setCurrentAppeals } from "../../../store/appeals/appealsSlice";
import { commentField, requiredField } from "../../../utils/validators/validators";
import { openErrorNotification } from "../../../utils/message/message";

type Select = {
  value: number;
  label: string;
};

export default function AppealsModal({
  terminalId,
  fetch,
  appealsId,
}: {
  terminalId: string;
  fetch: () => void;
  appealsId: string | null;
}) {
  const [dataForSelectStatus, setDataForSelectStatus] = useState<Array<Select> | undefined>(undefined);

  const statusList = useAppSelector((state) => state.appeals.statusList);
  const appeal = useAppSelector((state) => state.appeals.currentAppeals);

  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<addAppeals>();

  const onSubmit: SubmitHandler<addAppeals> = (data) => {
    if (appealsId) {
      dispatch(editAppeal({ AppealId: appealsId, comment: data.comment, appealStatus: data.status }));
      fetch();
    } else {
      openErrorNotification("Ошибка");
    }
  };

  useEffect(() => {
    let status = undefined;
    if (statusList) {
      statusList.forEach((e) => {
        if (e.status === appeal?.status) {
          status = e.id;
        }
      });
    }
    reset({
      terminalId,
      status: status,
      comment: appeal?.comment,
    });
  }, [terminalId, appeal]);

  useEffect(() => {
    if (statusList) {
      setDataForSelectStatus(
        statusList.map((e: any) => {
          return {
            value: e.id,
            label: e.status,
          };
        })
      );
    }
  }, [statusList]);

  useEffect(() => {
    if (appealsId) {
      dispatch(getAppeal({ AppealId: appealsId }));
    }
    return () => {
      dispatch(setCurrentAppeals(null));
    };
  }, []);

  return (
    <div className="terminal__modal-inner terminal__appeals">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="terminal__appeals-top">
          <div className="terminal__appeals-left">
            <div className="input-inner">
              <div className="input-inner__row">
                <div className="terminal__appeals-item">
                  <div className="terminal__appeals-item-title">Абонент</div>
                  <div className="terminal__appeals-item-title">
                    Почта: {appeal?.abonent?.email} <br />
                    Телефон: {appeal?.abonent?.phoneNumber} <br />
                    Номер: {appeal?.abonent?.personalAcc} <br />
                  </div>
                </div>
              </div>
              <div className="input-inner__row">
                <div className="terminal__appeals-item">
                  <div className="terminal__appeals-item-title">Статус</div>
                  <Controller
                    name="status"
                    control={control}
                    rules={requiredField}
                    render={({ field }) => (
                      <Select
                        {...field}
                        style={{ width: 120 }}
                        placeholder={"Выберите статус"}
                        options={dataForSelectStatus}
                      />
                    )}
                  />
                  {errors?.status && (
                    <div className="input__error">{errors.status?.message ? errors.status?.message : "Ошибка"}</div>
                  )}
                </div>
                <div className="terminal__appeals-item">
                  <div className="terminal__appeals-item-title">Тип обращения</div>
                  <div className="terminal__appeals-item-title">{appeal?.appealType}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="terminal__appeals-right">
            <div className="terminal__appeals-item">
              <div className="input">
                <div className="terminal__appeals-item-title">Комментарий</div>
                <Controller
                  name="comment"
                  control={control}
                  rules={commentField}
                  render={({ field }) => (
                    <TextArea placeholder="Комментарий" autoSize={{ minRows: 2, maxRows: 6 }} {...field} />
                  )}
                />
                {errors?.comment && (
                  <div className="input__error">{errors.comment?.message ? errors.comment?.message : "Ошибка"}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="terminal__appeals-bottom">
          <Button className="terminal__appeals-bottom-btn" type="primary" htmlType="submit" size="large">
            Сохранить изменения
          </Button>
        </div>
      </form>
      {/* <div className="terminal__appeals-docs">
        <div className="terminal__appeals-item-title">Документы</div>
        <Table className="terminal__appeals-table" columns={columns} dataSource={data} pagination={false} />
        <Button className="terminal__appeals-docs-btn">Добавить</Button>
      </div>
      <div className="terminal__appeals-bottom">
        <Button className="terminal__appeals-bottom-btn" type="primary" size="large">
          Сохранить
        </Button>
      </div> */}
    </div>
  );
}
