export const phoneField = {
  required: "Поле обязательно для заполнение",
  maxLength: {
    value: 14,
    message: "Недопустимый формат",
  },
  minLength: {
    value: 14,
    message: "Недопустимый формат",
  },
};

export const requiredField = {
  required: "Поле обязательно для заполнение",
};

export const ipField = {
  required: "Поле обязательно для заполнение",
  pattern: {
    value:
      /^(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))$/i,
    message: "Недопустимый формат",
  },
};
export const commentField = {
  required: "Поле обязательно для заполнение",
  minLength: {
    value: 2,
    message: "Минимум 2 символа",
  },
};



export const loginField = {
  required: "Поле обязательно для заполнение",

  minLength: {
    value: 3,
    message: "Минимум 3 символа",
  },
};

export const passField = {
  required: "Поле обязательно для заполнение",

  minLength: {
    value: 3,
    message: "Минимум 3 символа",
  },
};

export const emailField = {
  required: "Поле обязательно для заполнение",
  pattern: {
    value: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
    message: "Недопустимый формат",
  },
};
