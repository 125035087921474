import { createAsyncThunk } from "@reduxjs/toolkit";
import { LoginData } from "../../utils/types/storeType";
import { authAPI } from "../../api";
import { openErrorNotification, openSuccessNotification } from "../../utils/message/message";
import { loadingStatus } from "../app/appSlice";
import { auth } from "./authSlice";
import Cookies from "js-cookie";


export const login = ({ email, password }: LoginData) => async (dispatch: any) => {
  dispatch(loadingStatus(true));

  try {
    const response = await authAPI.login(email, password);
    Cookies.set("accessToken", response.data.token);
    openSuccessNotification("Успешная авторизация");
    dispatch(auth(response.data));
  } catch (e: any) {
    if (e.response) {
      openErrorNotification("Ошибка авторизации. Неверные данные");
    }
    openErrorNotification("Ошибка авторизации.");
  }
  dispatch(loadingStatus(false));
};
