import { Button, Input, Modal, Table, TableColumnsType } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { OPERATOR_PATH } from "../../utils/const";
import { SearchProps } from "antd/es/input";
import { useAppDispatch, useAppSelector } from "../../store";
import { getUsers } from "../../store/users/usersThink";
import { userTypeExpansion } from "../../utils/types/commonType";
import AddNewOperator from "./modal/addNewOperator";

const columns: TableColumnsType<userTypeExpansion> = [
  {
    title: "ФИО",
    render: (a) => <Link to={`${OPERATOR_PATH}/${a.id}`}>{a.userName}</Link>,
  },
  {
    title: "Почта",
    dataIndex: "email",
  },
  {
    title: "Локация",
    dataIndex: "location",
    render: (a) => <span>{a === null ? "Не задана" : a.name}</span>,
  },
  {
    title: "Привязанные терминалы",
    dataIndex: "terminals",
    render: (_, { terminals }) => (
      <div className="administration__terminals">
        {terminals?.length === 0 && "Нет привязанных"}
        {terminals &&
          terminals.map((terminal) => {
            return <span key={terminal.currentIP} className="administration__terminals-item">{terminal.name} ({terminal.currentIP})</span>;
          })}
      </div>
    ),
  },
];

const { Search } = Input;

export default function Operators() {
  const users = useAppSelector((state) => state.users.usersList);
  const [addNewUserModal, setAddNewUserModal] = useState(false);

  const dispath = useAppDispatch();

  const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
    dispath(getUsers({ UserName: value }));
  };

  const closeModal = () => {
    setAddNewUserModal(false);
  };

  const fetch = () => {
    dispath(getUsers({}));
  };

  useEffect(() => {
    dispath(getUsers({}));
  }, []);

  return (
    <div className="administration__operators">
      <Button className="administration__add-btn" type="primary" size="large" onClick={() => setAddNewUserModal(true)}>
        Добавить оператора
      </Button>
      <div className="administration__search">
        <Search
          placeholder="Поиск..."
          allowClear
          enterButton="Поиск"
          size="large"
          onSearch={onSearch}
          style={{ maxWidth: "500px" }}
        />
      </div>
      <div className="administration__table">
        <Table dataSource={users} columns={columns} pagination={false} />
      </div>
      <Modal title="Добавление оператора" open={addNewUserModal} onOk={closeModal} onCancel={closeModal} footer={null}>
        <AddNewOperator fetch={fetch} onClose={closeModal} />
      </Modal>
    </div>
  );
}
