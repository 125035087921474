import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface InitialStateType {
  loading: boolean;
  init: boolean;
  isShowMenu: boolean;
  isAsideActive: boolean;
  typeApp: "mob" | "desk";
}

const initialState: InitialStateType = {
  loading: false,
  init: false,
  isShowMenu: false,
  typeApp: "desk",
  isAsideActive: true,
};

export const appReducer = createSlice({
  name: "app",
  initialState,
  reducers: {
    loadingStatus: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setInit: (state, action: PayloadAction<boolean>) => {
      state.init = action.payload;
    },
    setAsideActive: (state, action: PayloadAction<boolean>) => {
      state.isAsideActive = action.payload;
    },
    setTypeApp: (state, action: PayloadAction<"mob" | "desk">) => {
      state.typeApp = action.payload;
    },
    isShowMenu: (state, action: PayloadAction<boolean>) => {
      state.isShowMenu = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { loadingStatus, setInit, isShowMenu, setTypeApp, setAsideActive } = appReducer.actions;

export default appReducer.reducer;
