import { Button, DatePicker, Table, TableColumnsType, TableProps } from "antd";
import React, { useState } from "react";
import moment from "moment";

const { RangePicker } = DatePicker;

type OnChange = NonNullable<TableProps<DataType>["onChange"]>;
type Filters = Parameters<OnChange>[1];

type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

interface DataType {
  key: React.Key;
  date: string;
  importance: string;
  type: string;
  discription: string;
}

const columns: TableColumnsType<DataType> = [
  {
    title: "Дата",
    dataIndex: "date",
  },
  {
    title: "Важность",
    dataIndex: "importance",
    filters: [
      { text: "Очень важно", value: "очень важно" },
      { text: "Важно", value: "Важно" },
      { text: "Не важно", value: "Не важно" },
    ],
    onFilter: (value, record) => record.importance.startsWith(value as string),
  },
  {
    title: "Тип события",
    dataIndex: "type",
    filters: [
      { text: "Тип 1", value: "очень важно" },
      { text: "Тип 2", value: "Важно" },
      { text: "Тип 3", value: "Не важно" },
    ],
    onFilter: (value, record) => record.type.startsWith(value as string),
  },
  {
    title: "Описание",
    dataIndex: "discription",
  },
];

const data: DataType[] = [
  {
    key: "1",
    date: "2023-05-01::00:00:00",
    importance: "очень важно",
    type: "Тип 1",
    discription: "Описание",
  },
  {
    key: "2",
    date: "2023-05-02::00:00:00",
    importance: "Важно",
    type: "Тип 3",
    discription: "Описание",
  },
  {
    key: "3",
    date: "2023-04-01::00:00:00",
    importance: "очень важно",
    type: "Тип 1",
    discription: "Описание",
  },
  {
    key: "4",
    date: "2023-04-01::00:00:00",
    importance: "очень важно",
    type: "Тип 4",
    discription: "Описание",
  },
];

export default function TerminalJurnal({ id }: { id: string }) {
  const [filteredInfo, setFilteredInfo] = useState<Filters>({});
  const [sortedInfo, setSortedInfo] = useState<Sorts>({});

  const handleChange: OnChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter as Sorts);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };

  const onDateChange = (date: any, dateString: any) => {
    console.log(date, dateString)
  }

  return (
    <div className="jornal">
      <div className="jornal__title title">Журнал событий</div>

      <div className="jornal__filter">
        <div className="jornal__filter-text">
          Фильтр по дате и времени:
        </div>
        <RangePicker showTime onChange={onDateChange} />
      </div>

      <Table columns={columns} dataSource={data} onChange={handleChange} />
    </div>
  );
}
