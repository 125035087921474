import { DownloadOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import React from "react";
import { useAppSelector } from "../../../store";
import TerminalSettingsMain from "./TerminalSettingsMain";

export default function TerminalSettings() {
  const terminalInfo = useAppSelector((state) => state.terminals.currentTerminal);
  const terminalConfig = useAppSelector((state) => state.terminals.currentTerminalConfig);


  return (
    <div className="terminalSettings">
      <div className="terminalSettings__config">
        {terminalInfo && terminalConfig && (
          <TerminalSettingsMain terminalInfo={terminalInfo} terminalConfig={terminalConfig} />
        )}

        <div className="terminalSettings__title _h4">Конфигурация АСТИП</div>
        <Button className="terminalSettings__btn-down" type="primary" icon={<DownloadOutlined />}>
          Скачать файл конфигурации
        </Button>
      </div>
      <div className="terminalSettings__config-download">
        <div className="terminalSettings__title _h4">Загрузить конфигурацию на АСТИП</div>
        <div className="terminalSettings__inner">
          <Button>Обзор</Button>
          <Input placeholder="" />
          <Button type="primary">Загрузить файл конфигурации</Button>
        </div>
      </div>
      <div className="terminalSettings__config-download-rolik">
        <div className="terminalSettings__title _h4">Загрузить Рекламный ролик</div>
        <div className="terminalSettings__inner">
          <Button>Обзор</Button>
          <Input placeholder="" />
          <Button type="primary">Загрузить </Button>
        </div>
      </div>
    </div>
  );
}
