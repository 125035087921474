import { PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Table, TableProps } from "antd";
import React, { useEffect, useState } from "react";
import { getAppealsList, getStatusTypesList } from "../../../store/appeals/appealsThink";
import { useAppDispatch, useAppSelector } from "../../../store";
import AppealsModal from "../modal/AppealsModal";
import AddAppealsModal from "../modal/AddAppealsModal";
import { getAbonenetsList } from "../../../store/abonents/abonentsThink";
import { appelas } from "../../../utils/types/storeType";
import moment from "moment";

export default function TerminalAppeals({ id }: { id: string }) {
  const [modalAppealsAdd, setModalAppealsAdd] = useState(false);
  const [modalAppeals, setModalAppeals] = useState(false);
  const [appealsId, setAppealsId] = useState<string | null>(null);
  const { statusList, typesList, listAppeals } = useAppSelector((state) => state.appeals);

  const dispatch = useAppDispatch();

  const columns: TableProps<appelas>["columns"] = [
    {
      title: "Дата",
      defaultSortOrder: "descend",
      render: (obj) => {
        return (
          <button
            onClick={() => {
              setAppealsId(obj.id);
              setModalAppeals(true);
            }}
          >
            {moment(obj.dateTimeCreation).format("YYYY-MM-DD, HH:mm:ss")}
          </button>
        );
      },
      sorter: (a, b) => Date.parse(a.dateTimeCreation) - Date.parse(b.dateTimeCreation),
    },
    {
      title: "Тип обращения",
      dataIndex: "appealType",
      key: "appealType",
      filters: typesList
        ? typesList?.map((e) => ({
            text: e.type,
            value: e.type,
          }))
        : undefined,
      onFilter: (value, record) => record.appealType.indexOf(value as string) === 0,
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      filters: statusList
        ? statusList?.map((e) => ({
            text: e.status,
            value: e.status,
          }))
        : undefined,
      onFilter: (value, record) => record.status.indexOf(value as string) === 0,
    },
    {
      title: "Терминал",
      dataIndex: "terminal",
      render: (text) => {
        return <span>{text.name}</span>;
      },
    },
  ];

  const fetch = () => {
    dispatch(getAppealsList({ AstipId: id }));
  };

  useEffect(() => {
    dispatch(getStatusTypesList());
    dispatch(getAppealsList({ AstipId: id }));
    dispatch(getAbonenetsList({}));
  }, []);

  return (
    <>
      <div className="terminal__appeals-wrap">
        <div className="terminal__appeals-title">Обращения</div>
        <Button
          className="terminal__appeals-btn"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setModalAppealsAdd(true)}
        >
          Добавить обращение
        </Button>
        <div className="terminal__table">
          <Table pagination={false} dataSource={listAppeals} columns={columns} />
        </div>
      </div>
      <Modal
        open={modalAppealsAdd}
        title="Добавление обращения"
        centered
        width={"100%"}
        style={{ maxWidth: "1000px", padding: "20px" }}
        className="terminal__modal"
        onOk={() => setModalAppealsAdd(false)}
        onCancel={() => setModalAppealsAdd(false)}
        footer={null}
      >
        <AddAppealsModal terminalId={id} fetch={fetch} />
      </Modal>
      <Modal
        open={modalAppeals}
        title="Информация по обращению обращения"
        centered
        width={"100%"}
        style={{ maxWidth: "1000px", padding: "20px" }}
        className="terminal__modal"
        onOk={() => setModalAppeals(false)}
        onCancel={() => setModalAppeals(false)}
        footer={null}
      >
        <AppealsModal terminalId={id} fetch={fetch} appealsId={appealsId} />
      </Modal>
    </>
  );
}
